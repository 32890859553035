<template>
  <section class="brick-or-cinder-block">
    <section class="hero">
      <div class="hero-container">
        <h1>Brique ou parpaing pour la construction : un débat dépassé ?</h1>
      </div>
    </section>
    <div class="hero-img">
      <img
        src="@/assets/img/pages/brick-or-cinder-block.png"
        alt="Brique ou parpaing pour la construction : un débat dépassé ?"
      />
    </div>
    <article class="grid-container article">
      <div class="article-title fit-content">
        <h3>
          Quel matériau a le meilleur potentiel pour réduire le coût de construction d’une maison et limiter, dans le temps, l’impact énergétique de l’habitat ? La question ne doit-elle pas plutôt se fonder sur d’autres considérations préalables, liées par exemple au type d’architecture à privilégier pour dépenser moins ?
        </h3>
      </div>

      <div class="article-card fit-content">
        <div class="article-card-container">
          <p>
            Entre brique et parpaing, le « cœur » de nombreux acquéreurs d’un <router-link :to="{ name: 'new-real-estate' }">logement neuf</router-link>, balance. Un choix d’autant plus cornélien qu’il croise et combine plusieurs facteurs de motivation, esthétiques pour les uns, économiques pour d’autres, sans omettre les préoccupations écologiques qui figurent aujourd’hui dans l’ordre de priorités d’un nombre croissant de candidats à l’achat d’un bien immobilier.
          </p>
          <p>
            Il est établi que la brique coûte plus cher que le parpaing, mais les écarts sont à relativiser car chacun de ces deux matériaux se décline en plusieurs modèles dont les prix varient en fonction de leur format, leur composition, leur finition, leur propriété isolante, et leur usage dans la construction : un bloc de parpaing par exemple peut être plein ou creux, constitué de granulats courants ou légers et présenter une face à enduire (standard) ou un revêtement destiné à rester apparent. Il existe aussi plusieurs types de briques : creuse (à alvéoles le plus souvent), monomur, rouge (ou pleine), réfractaire (pour les murs exposés à de très fortes chaleurs), ou de parement (briques dites moulées ou pressées).
          </p>
        </div>
      </div>

      <div class="article-title fit-content">
        <h3>
          Une maison compacte moins « énergivore »
        </h3>
      </div>

      <div class="article-card fit-content">
        <div class="article-card-container">
          <p>
            En 2016, le site ForumConstruire.com avait, suite à un sondage réalisé à partir de 1 236 témoignages, estimé qu’à l’époque (mais la tendance a pu se rééquilibrer depuis) que le parpaing était la ressource la plus utilisée par les français (à 48% contre 37% pour la brique). Plusieurs raisons à cela : on l’a dit, le coût de départ du premier est plus faible et son écobilan est, selon plusieurs estimations concordantes, également meilleur : autrement dit, la quantité d’énergie consommée tout au long de son cycle de vie, depuis la fabrication jusqu’au recyclage du matériau, est moins importante (350 KwH/ m3 contre 700 KwH/m).
          </p>
          <p>
            Le parpaing présente deux autres avantages : il possède une bonne résistance mécanique (face au gel notamment) et est incombustible. Il est, en revanche, moins performant sur le plan thermique que la brique et impliquera, de fait, des installations complémentaires pour parfaire l’isolation de l’habitat, avec un surcoût non négligeable à la clé. Sa capacité à réguler l’humidité est également moindre par rapport à sa concurrente en terre cuite.
          </p>
          <p>
            Une fois ces constats faits, doit-ont en conclure que l’efficacité énergétique d’une maison dépend uniquement de la nature des matériaux qui la composent ? Dès la conception, la prise en compte de cet enjeu environnemental induit d’autres perspectives liées à l’architecture et la configuration des lieux : le concept d’habitat compact, qui consiste à diminuer la surface de déperditions de chaleur (les murs externes) s’inscrit dans cette logique. Indépendamment du fait qu’elle soit faite de briques ou de parpaings, une maison de rangée (avec deux façades donc) ou en forme de cube consommera mécaniquement moins d’énergie qu’un logement en L.
          </p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'brick-or-cinder-block',
};
</script>

<style lang="sass">
.brick-or-cinder-block
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    background-color: darken($primary, 5%)
    height: 35rem
    display: flex
    flex-direction: column
    align-items: center
    &-container
      margin-top: 8rem
      width: 60rem
      h1
        color: $white
        text-align: center
    &-img
      display: flex
      flex-direction: row
      justify-content: center
      img
        border-radius: $global-border-radius
        margin-top: -15rem
  .article
    &-title
      display: flex
      justify-content: center
      margin: 4.5rem auto 0 auto
      h3
        text-align: center
    &-card
      margin: 4.5rem auto 0 auto
      &-container
        background-color: $white
        border-radius: $global-border-radius
        box-shadow: $drop-shadow
        padding: 2rem
        a
          text-decoration: none
          font-size: inherit
          color: $primary
      &-img
        margin-top: 4.5rem
        img
          width: 100%
          border-radius: $global-border-radius
      p:not(:last-child)
        margin-bottom: 1rem
      a
        font-weight: normal !important
    &-loan
      margin: 4.5rem auto 0 auto
      > h2
        margin-bottom: 2.5rem
        text-align: center
      h2
        color: $primary
      &-img
        margin-bottom: 2.5rem
        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius
      .card-type-img
        margin-bottom: 4.5rem
      &-text
        margin-bottom: 4.5rem
        p:not(:last-child)
          margin-bottom: 1rem
        a
          @include hyperlink
          text-decoration: none
          font-size: inherit
          font-weight: normal
  .fit-content
    width: 90%
    max-width: 950px
  @media (max-width: 768px)
    .hero
      height: 20rem
      &-container
        margin-top: 6rem
        width: 90%
      &-img
        img
          object-fit: cover
          width: 90%
          margin-top: -5rem
    .article
      &-title
        margin: 2.5rem auto 0 auto
      &-card
        margin: 2.5rem auto 0 auto
        &-container
          padding: 2rem 1rem
      &-loan
        margin: 3rem auto 0 auto
        h2
          margin-bottom: 0
        &-img
          margin-top: 1.5rem
        &-text
          margin-top: 1.5rem
</style>
